import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import Logo from "../../images/logo-v1.png"
import linkedin from "../../images/linkedin.svg"
import twitter from "../../images/twitter.svg"
import facebook from "../../images/facebook.svg"
import instagram from "../../images/instagram.svg"

function Footer(props) {
  return (
    <>
      <footer className="">
        <div className="footer-bg">
          <div className="container">
            <div className="row pt-5">
              <div
                className="col-lg-5 col-sm-6 col-12 about-widget footer-information"
                data-aos="fade-up"
              >
                <Link to="/" className="logo">
                  <img src={Logo} alt="Business IQ logo" width="220" />
                </Link>
                <p className=" mb-0 mt-4">
                  The BusinessIQ Africa Investment Club helps you invest your
                  money in secured investments using the principle of compound
                  interest by investing in subscription and real estate
                  businesses to multiply and grow your money by 300% or more.
                </p>
              </div>

              <div className="col-lg-3 col-sm-6 col-12 about-widget footer-information">
                <h5 class="title">Address</h5>
                <p className="mb-0 mt-3">
                  Simplinteriors Place <br />
                  71 Falolu Road Surulere, Lagos
                </p>
                <a
                  className="email font-italic"
                  href="mailto:investmentclub@businessiqafrica.com 
              "
                >
                  investmentclub@businessiqafrica.com
                </a>
                <a href="tel:+2348023157234" className="phone">
                  +234 8023 157 234
                </a>

                <ul className="list-inline mt-3">
                  <li className="list-inline-item mr-2">
                    <a href="https://www.linkedin.com/company/businessiq-magazine/?viewAsKember=true">
                      <img
                        src={linkedin}
                        alt="linkedin"
                        className="img-fluid"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      href="https://www.twitter.com/businessiqafri1"
                      target="__blank"
                      rel="noreferrer"
                      title="Twitter profile"
                    >
                      <img src={twitter} alt="Twitter profile" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      href="https://www.facebook.com/biziqafrica"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook Profile"
                    >
                      <img src={facebook} alt="Facebook profile" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      href="https://www.instagram.com/businessiqafrica"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram Profile"
                    >
                      <img src={instagram} alt="Instagram profile" />
                    </a>
                  </li>
                </ul>
              </div>

              <div
                class="col-lg-4 col-sm-6 col-12 footer-list aos-init aos-animate"
                data-aos="fade-up"
              >
                <h5 class="title">Company</h5>
                <ul class="list-unstyled">
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/page/about-us"
                      rel="noreferrer"
                      target="_blank"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/magazine"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Magazine
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/case-study"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Case Studies
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/books"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Books
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.businessiqafrica.com/blog"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://businessiqphilanthropy.org/"
                      target="__blank"
                      rel="noreferrer"
                    >
                      Philanthropy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="bottom-footer mt-0">
              <div className="clearfix">
                <p className="float-md-left ml-3 ml-lg-0">
                  © 2020 copyright all right reserved
                </p>
                <ul className="float-md-right">
                  <li>
                    <a href="privacy_policy.html">Privacy &amp; Policy.</a>
                  </li>
                  {/* <li><a href="terms.html">Terms.</a></li> */}
                  <li>
                    <a href="subscription.html">Subscription.</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* bottom-footer  */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
