/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ToastProvider } from "react-toast-notifications"

import "bootstrap/dist/css/bootstrap.min.css"

import "../../style/style.css"

import Header from "./Header"
import Footer from "./Footer"

const Layout = props => {
  return (
    <ToastProvider>
      <div>
        {!props.headerVisible && <Header />}
        {props.children}
        <Footer
          visible={props.downloadFooter}
          footerVisible={props.footerVisible}
        />
      </div>
    </ToastProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
