import React, { useState } from "react"
import { Link } from "gatsby"
// import { getAppDownloadUrl } from "./utils/index"

import Logo from "../../images/logo-v1.png"

const Nav = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="">
        <nav
          className="
            navbar
            active_nav
            navbar-expand-lg navbar-light
            py-md-0
            mob-pad
          "
        >
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Business Logo" width="200" className="" />
          </Link>
          <button
            className={open ? "navbar-toggler" : "navbar-toggler collapsed"}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setOpen(!open)
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={
              open
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto landing-page">
              <li className="nav-item mx-md-4 my-md-0 postion-relative">
                <Link className="nav-link px-0 active" to="/">
                  Home
                </Link>
              </li>

              <li className="nav-item mx-md-4 my-md-0 postion-relative">
                <Link className="nav-link px-0" to="#about">
                  About
                </Link>
              </li>

              <li className="nav-item mx-md-4 my-md-0 postion-relative">
                <Link className="nav-link px-0" to="#packages">
                  Packages
                </Link>
              </li>

              <li className="nav-item mx-md-4 my-md-0 postion-relative">
                <Link className="nav-link px-0" to="#benefits">
                  Benefits
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Nav
